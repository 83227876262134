input:focus {
  border: none;
}
.pointer {
  cursor: pointer;
}
.footer {
  width: 100.5%;
}
.footer a {
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 1500px) {
  .cardTable {
    display: none;
  }
  .cardMobile {
    display: block;
  }
  .container-fluid {
    width: 100% !important;
  }
}
@media screen and (min-width: 1501px) {
  .cardMobile .card {
    display: none;
  }
  .cardTable {
    display: block;
  }
  .rowCard {
    display: none;
  }
}
