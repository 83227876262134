*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
}
.wrapper{
    width: 100%;
    background-color: rgb(69, 160, 224);
    min-height: 969px;
    padding-top: 10%;
}
.container1 {
    width:60%;
    margin: 0% auto;
    background-color: rgb(218, 218, 218);
    box-shadow: 2px 2px 2px 2px grey;
    border-radius: 7px;
}
.maincontainer{
    width: 100%;
}
.col2{
    padding-top: 20%;
}
@media screen and (max-width:1000px) {
    .wrapper{
        width: 100%;
        background-color: rgb(69, 160, 224);
        min-height: 872px;
        padding-top: 10%;
    }
}