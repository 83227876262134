.containerProduct{
    width: 70%;
    margin: 2% auto;
}
.footer{
    width: 100%;
}
.footer a{
    color: white;
    text-decoration: none;
}