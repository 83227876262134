.mainProduct {
  width: 100%;
}
.carousel .slide img {
  width: 60% !important;
}
.footer {
  width: 100%;
}
.footer a {
  color: white;
  text-decoration: none;
}
th,
td {
  border: 1px solid black !important;
  padding: 10px;
}
img {
  max-width: 100%;
}
